@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.text-center{
 text-align: center;
}
html {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    color: #fff;
}

body {
    min-height: 100vh;
    background-color: #EFF6FC;
}

.App {
  display: flex;
  flex-direction: column;
  direction: rtl;

  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;
}
.C1111{
    width: 520px;
}
.c1{
    margin-left: 20%;
}
section{
    width: 100%;
    max-width: 520px;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 25px;
  
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

a, a:visited {
    color: #fff;
}
td{
    color: #000;
}

h1{
    color: #000;
}
h5 {
    color: #000;
    text-align: center; 
    text-decoration: none;
}
a{
    color: #000;
    text-align: center; 
    text-decoration: none;
}
label,
button {
 
  color: #000;
}

button {
  padding: 0.5rem;
}

li {
    list-style-type: none;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.flexGrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}


.persistCheck {
    font-size: 0.75rem;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.persistCheck label {
    margin: 0;
}

[type="checkbox"] {
    height: 20px;
    width: 20px;
    margin: 0 5px 2px 2px;
}

button{
    background-color: #EFF6FC;
}
.Home{
    width: 100%;
    width: 920px;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: #ffffff;

  
}
.C0{
    width: 1000px;
    display: flex;
    justify-content: center;
   margin-left: auto;
   margin-right: auto;
}
.C{
font-size: 40px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  justify-content: center;
    text-align: center;
    background-color: red;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    color: #fff;
}
.R{
    color: red; 
    display: flex;
    margin-left: auto;
    margin-right: auto;
  justify-content: center;
    text-align: center;
}
.label{
    float: right;
}
.label2{
    padding-right: 20px;
    float: right;
    text-align: right;
    width: auto;
  
}
.input{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    padding: 0.25rem;
    height:35px ;
    width: 300px;
    border-radius:10px ;
}
.input1{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    padding: 0.25rem;
    height:37px ;
    width: 300px;
    border-radius:10px ;
}
.input2{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    padding: 0.25rem;
    height:37px ;
    width: 400px;
    border-radius:10px ;
}


select{
    border: #DFDFDF 2px solid;
    width: 100px;
    height:30px ;
    border-radius: 5px;
    width: 200px;
}
.flex{
    display: flex;
    justify-content: space-around;
}
.button{
    display: flex;
    margin-left: auto;
    margin-right: auto;
  justify-content: center;
    text-align: center;
    width: 400px;
}
.c0{
   
    margin-left: 62%;
    justify-content: left;
    float: right;
    text-align: right;
    width: 200px;
    
}
.xc{
    background-color: #F8ADA1;
    display: flex;
    margin-left: 100px;
    margin-right: auto;
    justify-content: center;
}
.label1{
   background-color: #F8ADA1;
   border-radius: 15px ;
  text-align: center;
   width: 70px;
   margin-right: 10px ;
}
.Flex1{
    display: flex;
}

.box{
width: 200px;
height: 170px;
background-color: #EFF6FC;
border-radius: 25px ;
}
.center1{


    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.center{

    display: flex;
       justify-content: center;
       margin-left: auto;
       margin-right: auto;
   }
.kk{
    margin-left: 15px;
}
.h3{
    color:black;
    text-emphasis: none;
    text-align: center;
    justify-content: center;
}
.b2{
    margin-right:11%;}
    #customers {
        color: blue;
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      #customers td, #customers th {
        border: 1px solid #ddd;
        padding: 8px;
        color: customers;
        text-align: center ;
      }
      
      #customers tr:nth-child(even){background-color: #f2f2f2;}
      
      #customers tr:hover {background-color: #ddd;}
      
      #customers th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color:  #1976D2;
        color: black;
      }
      .W100{
        width: 100vw;
       
      }
 .MuiDataGrid-panelContent {
   
      background-color: white;
     
      
      }
.MuiPaper-root{
    background-color: none;
}
      .MuiDataGrid-panelWrapper 
      {
      margin-top: -140px;
        /* padding-bottom:100px; */
      background-color: none;
      } 
.input0,button{
    border: #DFDFDF 2px solid;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    padding: 0.25rem;
    border-radius: 0.5rem;
}
.bg{
    margin-top: 25vh;
background-color: rgba(0, 0, 0, 0.701);
width: 40vw;
height: 40vh;
border-radius: 25px;
}
.bg1{
    margin-top: 25vh;
background-color: rgba(0, 0, 0, 0.701);
width: 40vw;
height: 40vh;
border-radius: 25px;
}
.ml{
    margin-left: 20px;
margin-top: 20px;
}